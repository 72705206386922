import React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

export default function Services() {
  return (
    <Layout>
      <section>
        <div className='container'>
            <div className='row d-flex justify-content-center'>
                <div className="col-sm-12 col-lg-8 mb-5">
                    
                    <h4>Custom Leaded Glass</h4>

                        <p>
                            If you're interested in changing the look of your front door glass with a different design, or a window around the house you'd like dressed up a bit, or maybe just desire a little more obscurity for privacy? Along with stained glass repair services in Austin, Beheben offers custom leaded glass design services as well. 
                        </p>    
                        <p>
                            Maybe something with your own personal touch to make it unique to your environment and your signature.
                        </p>
                </div>

            </div>
            <div className='row mb-5 d-flex justify-content-center'>
                <div className="col-sm-12 col-lg-4 mb-3"> 

                    <h4 className='d-flex justify-content-center mb-3'><u>Before</u></h4>
                    
                    <div className='d-flex justify-content-center'>
                        <a target='_blank' href='https://beheben.net/images/20140219_103256_resized.jpg'>
                            <StaticImage
                                src='../images/20140219_103256_resized.jpg'
                                height = { 300 }
                                alt='logo'
                                placeholder='tracedSVG'
                                quality='40'
                            />
                        </a>
                    </div>

                    <small className='d-flex justify-content-center'>(Click to Enlarge)</small> 

                </div> 
                <div className="col-sm-12 col-lg-4"> 

                    <h4 className='d-flex justify-content-center mb-3'><u>After</u></h4>

                    <div className='d-flex justify-content-center'>
                        <a target='_blank' href='https://beheben.net/images/20140219_104734_resized-1024x575-1.jpg'>
                            <StaticImage
                                src='../images/20140219_104734_resized-1024x575-1.jpg'
                                height = { 300 }
                                alt='logo'
                                placeholder='tracedSVG'
                                quality='40'
                            />
                        </a>

                    </div>


                    <small className='d-flex justify-content-center'>(Click to Enlarge)</small> 

</div> 
                 
            </div>
        </div>
      </section>   
    </Layout>
  )
}
